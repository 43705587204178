import React from 'react'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Testimonial from '@widgets/Testimonial'
import Sezione3 from '@widgets/Sezione3'
import Sezione4 from '@widgets/Sezione4'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Barra_bassa from '@widgets/Barra_bassa/Barra_bassa'
import Sezione5 from '@widgets/Sezione5'
import Sezione7 from '@widgets/Sezione7'
import Sezione8 from '@widgets/Sezione8'
import Sezione9 from '@widgets/Sezione9'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Sezione10 from '@widgets/Sezione10'
import Faq from '@widgets/Faq/Faq'
import Sezionepage from '@widgets/Sezionepage/Sezionepage'
import Sezione11 from '@widgets/Sezione11'
const Prova = props  => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
<Barra_bassa>
  
</Barra_bassa>

      <Seo title='Rimborsi Aerei'  lang="it" description="Il tuo volo ha subito un ritardo o è stato cancellato?
Ottieni fino a 600 € di risarcimento per passeggero, a prescindere dal prezzo del biglietto. Se non vinciamo, non ci devi nulla!"/>
      <Divider />
    
      
     <Divider />
  
      <Stack>
        <Main>
          <Sezione11 />
        </Main>
      </Stack>
      <Stack>
        <Main>
          <ContactForm />
        </Main>
      </Stack>
        
      
    
    </Layout>
  )
}

export default Prova